/**
 * Cookies that the application uses
 */
export class Cookies {
  /**
   * The general language
   */
  static readonly LANG = 'lang';

  /**
   * The user's preferred language from their settings
   */
  static readonly USER_LANG = 'u_l';

  /**
   * IP address
   */
  static readonly IP = 'i_p';

  /**
   * Unique user id
   */
  static readonly UUID = 'uuid';

  /**
   * Logged user unique id
   */
  static readonly LOGGED_USER_UUID = 'l_u_uuid';

  /**
   * User's current location
   */
  static readonly USER_CURRENT_LOCATION = 'u_c_l';

  /**
   * Environment information
   */
  static readonly ENVIRONMENT = 'environment';

  /**
   * Last modified timestamp
   */
  static readonly LAST_MODIFIED = 'last_modified';

  /**
   * Authentication token
   */
  static readonly AUTH_TOKEN = 'auth_token';

  /**
   * Reference token
   */
  static readonly REF = 'ref';

  static readonly PREVIOUS_ROLE = 'previous_role';
}
