import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppSettings, SettingsService } from '@services/settings.service';
import { LocalStorageKeys } from './shared/constants/local-storage.constants';
import * as uuid from 'uuid';
import _ from 'lodash';
import { environment } from 'src/environments/environment';
import { NgProgressbar } from 'ngx-progressbar';
import { NgProgressHttp } from 'ngx-progressbar/http'; 

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NgClass,
    NgProgressbar,
    NgProgressHttp
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  settingsService = inject(SettingsService);
  appSettings: AppSettings = this.settingsService.appSettings;

  ngOnInit(): void {
    console.log("first");
    if (!environment.production) {
      console.log('Environment = ' + environment.name);
    }
    this.setFrontEndSettings();
  }

  setFrontEndSettings(): void {
    // Check if secret key is set
    if (!localStorage?.getItem(LocalStorageKeys.SECRET_KEY)) {
      if (_.isEmpty(localStorage?.getItem(LocalStorageKeys.SECRET_KEY))) {
        let uid = uuid.v4();
        console.log(uid);
        localStorage?.setItem(LocalStorageKeys.SECRET_KEY, uid);
      }
    }
  }
}
