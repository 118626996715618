import { Dropdown } from "../../models/dropdown.model";
import { ContractClientEndReason, ContractCrewEndReason } from "../enums/enums";

export class APP {

    static readonly NAME = 'iKonnect';

    static readonly VERSION = '1.2.0';

    static readonly AUTH_TOKEN = 'token';

    static readonly USER_DETAIL = 'user_session_detail';

    static readonly APP_TIME_ZONE = "UTC";
    /**
     * Key is unique | Value is the name user's see
     */
    static readonly LANGUAGES: Dropdown[] = [
        { value: 'en', text: 'English' },
        { value: 'fr', text: 'French - Fran�ais' }
    ];

    static readonly EXPERIENCE_LEVELS: Dropdown[] = [
        { value: '0', text: 'No Experience' },
        { value: '1', text: 'Beginner' },
        { value: '2', text: 'Intermediate' },
        { value: '3`', text: 'Expert' }

    ];

    static readonly DEFAULT_LANGUAGE = 'en';

    static readonly IMAGE_SIZE = 5000000;

    static readonly quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline'], // Formatting options
            [{ 'size': ['small', false, 'large', 'huge'] }], // Size options
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Header options
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // List options
            [{ 'align': [] }] // Alignment options
        ]
    };

    /**
     * The routes we want the dashboard to be surround by
     */
    static readonly DashboardRoutes = [
        '/client/dashboard',
        '/crew-member/dashboard',
        '/notifications',
        '/test'
    ];

    static readonly CONTRACT_CLIENT_END_REASONS: Dropdown[] = [
        { value: ContractClientEndReason.ProjectCompletedSuccessfully, text: 'project.completed.successfully' },
        { value: ContractClientEndReason.ProblemWorkingWithTalent, text: 'problem.working.with.talent' },
        { value: ContractClientEndReason.BudgetingOrPricingConcerns, text: 'budgeting.or.pricing.concerns' },
        { value: ContractClientEndReason.TalentIsNoLongerNeeded, text: 'talent.is.no.longer.needed' },
        { value: ContractClientEndReason.RequestedByTalent, text: 'requested.by.talent' },
        { value: ContractClientEndReason.Other, text: 'other' }
    ];

    static readonly CONTRACT_CREW_END_REASONS: Dropdown[] = [
        { value: ContractCrewEndReason.ProjectCompletedSuccessfully, text: 'project.completed.successfully' },
        { value: ContractCrewEndReason.ProblemWithClient, text: 'problem.with.client' },
        { value: ContractCrewEndReason.NoLongerAvailable, text: 'no.longer.available' },
        { value: ContractCrewEndReason.DisagreementOnProject, text: 'disagreement.on.project' },
        { value: ContractCrewEndReason.RequestedByClient, text: 'requested.by.client' },
        { value: ContractCrewEndReason.Other, text: 'other' }
    ];

    /**
     * A mapping of client-specific contract end reasons to their corresponding display text.
     * Used for quickly retrieving the text description based on the reason value.
     */
    static readonly ReasonClientReasonTextMap = new Map(
        this.CONTRACT_CLIENT_END_REASONS.map(reason => [reason.value, reason.text])
    );

    /**
     * A mapping of crew-specific contract end reasons to their corresponding display text.
     * Used for quickly retrieving the text description based on the reason value.
     */
    static readonly ReasonCrewReasonTextMap = new Map(
        this.CONTRACT_CREW_END_REASONS.map(reason => [reason.value, reason.text])
    );

}


export class Constants {
    public static STORAGE_TOKEN = "ikonnect_token";
    public static THEME = "ikonnect_theme";
    public static USER_INFO = "ikonnect_user_info";
}

export class FileConstants {

    /**
     * This constant defines the MIME types of all the file types that are accepted for upload in the system. It specifies a list of allowed file formats for image, video, audio, PDF, Word documents, Excel files, and PowerPoint presentations.
     * Usage: This constant is used to configure file upload inputs or file upload handlers in the application to restrict file selection to the defined MIME types.
     */
    public static readonly ACCEPTED_FILES_ALL = 'image/*, video/*, audio/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation';

}

export class SecondsToMilliseconds {

    static readonly ONE_SECOND = 1000;

    static readonly TWO_SECONDS = 2000;

    static readonly THREE_SECONDS = 3000;

    static readonly FOUR_SECONDS = 4000;

    static readonly FIVE_SECONDS = 5000;

    static readonly SIX_SECONDS = 6000;

    static readonly SEVEN_SECONDS = 7000;

    static readonly EIGHT_SECONDS = 8000;

    static readonly NINE_SECONDS = 9000;

    static readonly TEN_SECONDS = 10000;

    static readonly TWELVE_SECONDS = 12000;

}