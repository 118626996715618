export class GeneralUrl {

  static readonly LOGIN = 'login';

  static readonly SIGNUP = 'signup';

  static readonly REGISTRATION_CONFIRMATION = 'registration-confirmation';

  static readonly FORGOT_PASSWORD = 'forgot-password';

  static readonly SUPPORT = 'support';

  static readonly ACCESS_DENIED =  'access-denied';

}

export class ClientUrl {

  static readonly CLIENT_ONBOARDING = 'client/onboarding';

  static readonly FEED = 'client/dashboard/feed';

  static readonly FIND_CREW_MEMBERS = 'client/dashboard/find-crew-members';

  static readonly PROJECTS = 'client/dashboard/projects';
  static readonly CREATE_PROJECT = 'client/dashboard/projects/create-project';
 // /projects/create-project
  static readonly EDIT_PROJECT = 'client/dashboard/projects/edit-project';
  static readonly EDIT_PROJECT_ID = 'edit-project/:projectId'; // Relative path

  static readonly VIEW = 'client/dashboard/projects/view-project';
  static readonly VIEW_ID = 'view-project/:projectId'; // Relative path

  static readonly VIEW_PROJECT = 'client/dashboard/projects/view-project';
  static readonly VIEW_PROJECT_ID = 'view-project/:projectId'; // Relative path

  static readonly PREVIEW_PROJECT = 'client/dashboard/projects/preview-project';
  static readonly PREVIEW_PROJECT_ID = 'preview-project/:projectId'; // Relative path

  static readonly SEND_OFFER = 'client/dashboard/projects/:projectId/:proposalId/send-offer';
  static readonly SEND_OFFER_ID = 'client/dashboard/projects/:projectId/:proposalId/send-offer';

  static readonly CONTRACTS = 'client/dashboard/contracts'; 
  static readonly CONTRACT_ACTIVE = 'client/dashboard/contracts/active';
  static readonly CONTRACT_VIEW = 'client/dashboard/contracts/view';
  static readonly CONTRACT_END = 'client/dashboard/contracts/end';
  static readonly CONTRACT_ENDED = 'client/dashboard/contracts/ended';

  static readonly CONTRACT_WRITE_REVIEW = 'client/dashboard/contracts/write-review'; 

  static readonly MESSAGES = 'client/dashboard/messages';

  static readonly SAVED_TALENT = 'client/dashboard/saved-talent';

  static readonly CALENDAR = 'client/dashboard/calendar';

  static readonly PORTFOLIO = 'client/dashboard/portfolio';

  static readonly BILLINGS = 'client/dashboard/billings';

  static readonly SETTINGS_ACCOUNT = 'client/dashboard/settings/account';

  static readonly SETTINGS_PASSWORD = 'client/dashboard/settings/password';

  static readonly SETTINGS_NOTIFICATION = 'client/dashboard/settings/notification';

}

export class CrewMemberUrl {

  static readonly CREW_ONBOARDING = 'crew-member/onboarding';
  
  static readonly FEED = 'crew-member/dashboard/feed';

  static readonly FEED_MODAL_VIEW_PROJECT = 'crew-member/dashboard/feed/modal-view-project';

  static readonly APPLY = 'crew-member/dashboard/project/apply';

  static readonly APPLICATION = 'crew-member/dashboard/application';

  static readonly APPLICATION_ID = 'crew-member/dashboard/application/:proposalId/:projectTitle';


  static readonly FIND_PROJECTS = 'crew-member/dashboard/find-projects';

  static readonly CONTRACTS = 'crew-member/dashboard/contracts';
  static readonly CONTRACT_VIEW = 'crew-member/dashboard/contracts/view';
  static readonly CONTRACT_OVERVIEW = 'crew-member/dashboard/contracts/overview';
  static readonly CONTRACT_DETAIL = 'crew-member/dashboard/contracts/detail';
  static readonly CONTRACT_RENEGOTIATE = 'crew-member/dashboard/contracts/renegotiate';
  static readonly CONTRACT_SENT_DETAIL = 'crew-member/dashboard/contracts/sent-detail';
  static readonly CONTRACTS_OVERVIEW = 'crew-member/dashboard/contracts/overview';
  static readonly CONTRACT_END = 'crew-member/dashboard/contracts/end';

  static readonly CONTRACT_IN_PROGRESS = 'crew-member/dashboard/contracts/in-progress';


  static readonly MESSAGES = 'crew-member/dashboard/messages';

  static readonly SAVED = 'crew-member/dashboard/saved';

  static readonly CALENDAR = 'crew-member/dashboard/calendar';

  static readonly TEAMS = 'crew-member/dashboard/teams';

  static readonly PORTFOLIO = 'crew-member/dashboard/portfolio';

  static readonly EDIT_PORTFOLIO = 'crew-member/dashboard/edit-portfolio';

  static readonly PAYOUT = 'crew-member/dashboard/payouts';

  static readonly BILLINGS = 'crew-member/dashboard/billings';

  static readonly SETTINGS_ACCOUNT = 'crew-member/dashboard/settings/account';

  static readonly SETTINGS_PASSWORD = 'crew-member/dashboard/settings/password';

  static readonly SETTINGS_NOTIFICATION = 'crew-member/dashboard/settings/notification';
}